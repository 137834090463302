import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Avatar from '@mui/joy/Avatar';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import MenuButton from '@mui/joy/MenuButton';
import ListDivider from '@mui/joy/ListDivider';
import Dropdown from '@mui/joy/Dropdown';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import { NavLink } from 'react-router-dom';

function Header(props) {
  return (
    <Box
      component="header"
      className="Header"
      {...props}
      sx={[
        {
          p: 2,
          gap: 2,
          //   bgcolor: 'background.surface',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gridColumn: '1 / -1',
          //   borderBottom: '1px solid',
          //   borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 1100,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      <HeaderContents />
    </Box>
  );
}

function HeaderContents() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <IconButton
          component={NavLink}
          size="md"
          variant="outlined"
          color="neutral"
          sx={{
            borderRadius: '50%',
          }}
          to="/"
        >
          <HomeOutlinedIcon />
        </IconButton>
        <Typography level="title-sm" textColor="text.primary">
          Supergood Apps
        </Typography>
      </Stack>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        <Dropdown>
          <MenuButton
            variant="plain"
            size="lg"
            sx={{
              maxWidth: '32px',
              maxHeight: '32px',
            }}
          >
            <AppsOutlinedIcon
              sx={{
                maxWidth: '32px',
                maxHeight: '32px',
                minHeight: '24px',
                minWidth: '24px',
              }}
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem component={NavLink} to="/apps/meter">
              <EqualizerOutlinedIcon />
              Meter
            </MenuItem>
            <MenuItem component={NavLink} to="/clocker">
              <WatchLaterOutlinedIcon />
              Clock
            </MenuItem>
          </Menu>
        </Dropdown>
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{
              maxWidth: '32px',
              maxHeight: '32px',
              borderRadius: '9999999px',
            }}
          >
            <Avatar
              src="https://i.pravatar.cc/40?img=39"
              srcSet="https://i.pravatar.cc/80?img=39"
              sx={{ maxWidth: '32px', maxHeight: '32px' }}
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src="https://i.pravatar.cc/40?img=39"
                  srcSet="https://i.pravatar.cc/80?img=39"
                  sx={{ borderRadius: '50%' }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    Joe User
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    joe@email.com
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <HelpRoundedIcon />
              Help
            </MenuItem>
            <MenuItem>
              <SettingsRoundedIcon />
              Settings
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}

export default Header;
