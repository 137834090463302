function ErrorPage() {
  return (
    <div>
      <h1>Error</h1>
      <h2>404</h2>
    </div>
  );
}

export default ErrorPage;
