import React, { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import SGTracker from './SGTracker';

const withPage = (WrappedComponent, viewData) => {
  const WithPage = (props) => {
    const { Track, trackEvent } = useTracking(
      {
        ...SGTracker.defaults,
        [viewData.type]: viewData.context,
        ...viewData.contextData,
      },
      { dispatch: (data) => SGTracker.dispatch(data) }
    );

    useEffect(() => {
      trackEvent({ action: [viewData.type] + 'View' });
    });

    return (
      <Track>
        <WrappedComponent {...props} trackEvent={trackEvent} />
      </Track>
    );
  };
  return WithPage;
};

export default withPage;
