import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styles from './privacy.module.css';

function PrivacyPolicy() {
  return (
    <div className={styles.privacyBox}>
      <div className={styles.heading_1}>
        <strong>PRIVACY POLICY</strong>
      </div>
      <b>Last updated August 01, 2023 </b>
      <div>
        This privacy notice for Supergood, LLC ("Company," "we," "us," or
        "our"), describes how and why we might collect, store, use, and/or share
        ("process") your information when you use our services ("Services"),
        such as when you:
      </div>
      <ul>
        <li>
          Visit our website at app.supergood.uno, or any website of ours that
          links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <div>
        Questions or concerns? Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at
        privacy@supergood.uno.
      </div>
      <br></br>
      <strong>
        <span className={styles.heading_1}>SUMMARY OF KEY POINTS</span>
      </strong>
      <br />
      <div className={styles.body_text}>
        <strong>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our &nbsp;
          <HashLink to="#toc">table of contents</HashLink>
          below to find the section you are looking for.
        </strong>
      </div>
      <br />
      <div>
        <div className={styles.body_text}>
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with Supergood, LLC and the
          Services, the choices you make, and the products and features you use.
        </div>
      </div>
      <div className={styles.body_text}>
        <strong>Do we process any sensitive personal information?</strong> We do
        not process sensitive personal information.
      </div>
      <div className={styles.body_text}>
        <strong>Do we receive any information from third parties?</strong> We do
        not receive any information from third parties.
      </div>
      <div className={styles.body_text}>
        <strong>How do we process your information?</strong> We process your
        information to provide, improve, and administer our Services,
        communicate with you, for security and fraud prevention, and to comply
        with law. We may also process your information for other purposes with
        your consent. We process your information only when we have a valid
        legal reason to do so. Learn more about{' '}
        <HashLink to="#infouse">how we process your information</HashLink>
      </div>
      <div className={styles.body_text}>
        <strong>
          In what situations and with which parties do we share personal
          information?
        </strong>
        We may share information in specific situations and with specific third
        parties. Learn more about&nbsp;
        <HashLink to="#whoshare">
          when and with whom we share your personal information
        </HashLink>
        .
      </div>
      <div className={styles.body_text}>
        <strong>How do we keep your information safe?</strong> We have
        organizational and technical processes and procedures in place to
        protect your personal information. However, no electronic transmission
        over the internet or information storage technology can be guaranteed to
        be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals, or other unauthorized third parties will not be able to
        defeat our security and improperly collect, access, steal, or modify
        your information.
      </div>
      <div className={styles.body_text}>
        <strong>What are your rights?</strong> Depending on where you are
        located geographically, the applicable privacy law may mean you have
        certain rights regarding your personal information. Learn more
        about&nbsp;{' '}
        <HashLink to="#privacyrights">your privacy rights.</HashLink>
      </div>
      <div className={styles.body_text}>
        <strong>How do you exercise your rights?</strong> The easiest way to
        exercise your rights is by visiting , or by contacting us. We will
        consider and act upon any request in accordance with applicable data
        protection laws.
      </div>
      <div className={styles.heading_1}>
        <strong>TABLE OF CONTENTS</strong>
      </div>
      <div>
        <HashLink to="#infocollect">
          1. WHAT INFORMATION DO WE COLLECT?
        </HashLink>
      </div>
      <div>
        <HashLink to="#infouse">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </HashLink>
      </div>
      <div>
        <a href="#legalbases" data-custom-class="link">
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
          INFORMATION?
        </a>
      </div>
      <div>
        <HashLink to="#whoshare">
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </HashLink>
      </div>
      <div>
        <HashLink to="#cookies">
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </HashLink>
      </div>
      <div>
        <HashLink to="#sociallogins">
          6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </HashLink>
      </div>
      <div>
        <HashLink to="#inforetain">
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </HashLink>
      </div>
      <div>
        <HashLink to="#infosafe">
          8. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </HashLink>
      </div>
      <div>
        <HashLink to="#infominors">
          9. DO WE COLLECT INFORMATION FROM MINORS?
        </HashLink>
      </div>
      <div>
        <HashLink to="#privacyrights">
          10. WHAT ARE YOUR PRIVACY RIGHTS?
        </HashLink>
      </div>
      <div>
        <HashLink to="#DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</HashLink>
      </div>
      <div>
        <HashLink to="#caresidents">
          12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </HashLink>
      </div>
      <div>
        <HashLink to="#virginia">
          13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </HashLink>
      </div>
      <div>
        <HashLink to="#policyupdates">
          14. DO WE MAKE UPDATES TO THIS NOTICE?
        </HashLink>
      </div>
      <div>
        <HashLink to="#contact">
          15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </HashLink>
      </div>
      <div>
        <a href="#request" data-custom-class="link">
          16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </a>
      </div>
      <div id="infocollect" className={styles.heading_1}>
        <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
      </div>
      <div>
        <span className={styles.heading_2}>
          <strong>Personal information you disclose to us</strong>
        </span>
      </div>
      <div className={styles.body_text}>
        <strong>
          <em>In Short:</em>
        </strong>
        <em>We collect personal information that you provide to us.</em>
      </div>
      <div>
        <span className={styles.body_text}>
          We collect personal information that you voluntarily provide to us
          when you register on the Services,&nbsp;
        </span>
        <span className={styles.body_text}>
          express an interest in obtaining information about us or our products
          and Services, when you participate in activities on the Services, or
          otherwise when you contact us.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </span>
      </div>
      <ul>
        <li>Names</li>
        <li>Email addresses</li>
        <li>Usernames</li>
        <li>Passwords</li>
        <li>Contact preferences</li>
        <li>Contact or authentication data</li>
        <li>Billing addresses</li>
        <li>Debit/credit card numbers</li>
      </ul>
      <div>
        <span className={styles.body_text}>
          <strong>Sensitive Information.</strong> We do not process sensitive
          information.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you make purchases, such as your payment
          instrument number, and the security code associated with your payment
          instrument. All payment data is stored by.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>Social Media Login Data.&nbsp;</strong>We may provide you with
          the option to register with us using your existing social media
          account details, like your Facebook, Twitter, or other social media
          account. If you choose to register in this way, we will collect the
          information described in the section called called "HOW DO WE HANDLE
          YOUR SOCIAL LOGINS?" below.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </span>
      </div>
      <div>
        <span className={styles.heading_2}>
          <strong>Information automatically collected</strong>
        </span>
      </div>
      <div>
        <div>
          <span className={styles.body_text}>
            <span className={styles.body_text}>
              <strong>
                <em>In Short:</em>
              </strong>
            </span>
          </span>
          <span className={styles.body_text}>
            <span className={styles.body_text}>
              <em>
                Some information &mdash; such as your Internet Protocol (IP)
                address and/or browser and device characteristics &mdash; is
                collected automatically when you visit our Services.
              </em>
            </span>
          </span>
        </div>
      </div>
      <div>
        <span className={styles.body_text}>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          Like many businesses, we also collect information through cookies and
          similar technologies.{' '}
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          The information we collect includes:
        </span>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            <em>Log and Usage Data.</em> Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services(such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called "crash dumps" ), and
            hardware settings).
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <em>Device Data.</em> We collect device data such as information
            about your computer, phone, tablet, or other device you use to
            access the Services. Depending on the device used, this device data
            may include information such as your IP address (or proxy server),
            device and application identification numbers, location, browser
            type, hardware model, Internet service provider and/or mobile
            carrier, operating system, and system configuration information.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <em>Location Data.</em> We collect location data such as information
            about your device's location, which can be either precise or
            imprecise. How much information we collect depends on the type and
            settings of the device you use to access the Services. For example,
            we may use GPS and other technologies to collect geolocation data
            that tells us your current location (based on your IP address). You
            can opt out of allowing us to collect this information either by
            refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may
            not be able to use certain aspects of the Services.
          </span>
        </li>
      </ul>
      <div id="infouse" className={styles.heading_1}>
        <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
      </div>
      <div className={styles.body_text}>
        <strong>
          <em>In Short:&nbsp;</em>
        </strong>
        <em>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent.
        </em>
      </div>
      <div className={styles.body_text}>
        <strong>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </strong>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            <strong>
              To facilitate account creation and authentication and otherwise
              manage user accounts.&nbsp;
            </strong>
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>
              To deliver and facilitate delivery of services to the user.&nbsp;
            </strong>
            We may process your information to provide you with the requested
            service.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>
              To respond to user inquiries/offer support to users.&nbsp;
            </strong>
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>To send administrative information to you.&nbsp;</strong>
            We may process your information to send you details about our
            products and services, changes to our terms and policies, and other
            similar information.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <span className={styles.body_text}>
              <strong>To fulfill and manage your orders</strong>
              We may process your information to fulfill and manage your orders,
              payments, returns, and exchanges made through the Services.
            </span>
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>
              To send you marketing and promotional communications.&nbsp;
            </strong>
            We may process the personal information you send to us for our
            marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time.
            For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>To protect our Services.</strong> We may process your
            information as part of our efforts to keep our Services safe and
            secure, including fraud monitoring and prevention.
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            <strong>To identify usage trends.</strong> We may process
            information about how you use our Services to better understand how
            they are being used so we can improve them.
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            <strong>To save or protect an individual's vital interest.</strong>{' '}
            We may process your information when necessary to save or protect an
            individual&rsquo;s vital interest, such as to prevent harm.
          </span>
        </li>
      </ul>
      <div>
        <strong>
          <div id="legalbases" className={styles.heading_1}>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </div>
        </strong>
      </div>
      <div>
        <em>
          <span className={styles.body_text}>
            <strong>In Short:&nbsp;</strong>We only process your personal
            information when we believe it is necessary and we have a valid
            legal reason (i.e.,legal basis) to do so under applicable law, like
            with your consent, to comply with laws, to provide you with services
            to enter into or fulfill our contractual obligations, to protect
            your rights, or to fulfill our legitimate business interests.
          </span>
        </em>
      </div>
      <div>
        <em>
          <span className={styles.body_text}>
            <strong>
              <u>
                If you are located in the EU or UK, this section applies to you.
              </u>
            </strong>
          </span>
        </em>
      </div>
      <div>
        <span className={styles.body_text}>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </span>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            <strong>Consent.&nbsp;</strong>We may process your information if
            you have given us permission (i.e., consent) to use your personal
            information for a specific purpose. You can withdraw your consent at
            any time. Learn more about withdrawing your consent.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>Performance of a Contract.</strong> We may process your
            personal information when we believe it is necessary to fulfill our
            contractual obligations to you, including providing our Services or
            at your request prior to entering into a contract with you.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>Legitimate Interests.</strong> We may process your
            information when we believe it is reasonably necessary to achieve
            our legitimate business interests and those interests do not
            outweigh your interests and fundamental rights and freedoms. For
            example, we may process your personal information for some of the
            purposes described in order to:
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            Send users information about special offers and discounts on our
            products and services
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Analyze how our Services are used so we can improve them to engage
            and retain users
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Diagnose problems and/or prevent fraudulent activities
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>Legal Obligations.</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            <strong>Vital Interests.</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </span>
        </li>
      </ul>
      <div>
        <span className={styles.body_text}>
          <strong>
            <u>
              <em>
                If you are located in Canada, this section applies to you.
              </em>
            </u>
          </strong>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). You can withdraw your consent at any
          time.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </span>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            For investigations and fraud detection and prevention
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            For business transactions provided certain conditions are met
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            If the information is publicly available and is specified by the
            regulations
          </span>
        </li>
      </ul>
      <div>
        <strong>
          <div id="whoshare" className={styles.heading_1}>
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </div>
        </strong>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;We may share information in specific situations described in
            this section and/or with the following third parties.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We may need to share your personal information in the following
          situations:
        </span>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            <strong>Business Partners.</strong> We may share your information
            with our business partners to offer you certain products, services,
            or promotions.
          </span>
        </li>
      </ul>
      <div id="cookies" className={styles.heading_1}>
        <strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;We may use cookies and other tracking technologies to collect
            and store your information.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice .
        </span>
      </div>
      <div id="sociallogins" className={styles.heading_1}>
        <strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            If you choose to register or log in to our Services using a social
            media account, we may have access to certain information about you.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.{' '}
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </span>
      </div>
      <div id="inforetain" className={styles.heading_1}>
        <strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
      </div>{' '}
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy notice unless otherwise required
            by law.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </span>
      </div>
      <div id="infosafe" className={styles.heading_1}>
        <strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
      </div>{' '}
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We have implemented appropriate and reasonable technical and
          organizational
        </span>
      </div>
      <div id="infominors" className={styles.heading_1}>
        <strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong>
      </div>{' '}
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;We do not knowingly collect data from or market to children
            under 18 years of age.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of the Services. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at{' '}
        </span>
      </div>
      <div id="privacyrights" className={styles.heading_1}>
        <strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;In some regions, such as the European Economic Area (EEA),
            United Kingdom (UK), and Canada, you have rights that allow you
            greater access to and control over your personal information. You
            may review, change, or terminate your account at any time.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section "HOW CAN YOU CONTACT US ABOUT
          THIS NOTICE?" below.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your{' '}
          <span className={styles.body_text}>
            <span className={styles.body_text}>
              <a
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                target="_blank"
                rel="noopener noreferrer"
                data-custom-class="link"
              >
                Member State data protection authority
              </a>
            </span>
          </span>{' '}
          or&nbsp;
        </span>
        <a
          href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={styles.link}>
            <span className={styles.body_text}>
              UK data protection authority
            </span>
          </span>
        </a>
        .
      </div>
      <div>
        <span className={styles.body_text}>
          If you are located in Switzerland, you may contact the{' '}
          <span className={styles.body_text}>
            <span className={styles.body_text}>
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                target="_blank"
                rel="noopener noreferrer"
                data-custom-class="link"
              >
                Federal Data Protection and Information Commissioner
              </a>
            </span>
          </span>
          .
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            <u>Withdrawing your consent:</u>
          </strong>{' '}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section "HOW CAN YOU CONTACT
          US ABOUT THIS NOTICE?" below.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            <u>Opting out of marketing and promotional communications:</u>
          </strong>
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we
          send, or by contacting us using the details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be
          removed from the marketing lists. However, we may still communicate
          with you — for example, to send you service-related messages that are
          necessary for the administration and use of your account, to respond
          to service requests, or for other non-marketing purposes.
        </span>
      </div>
      <div>
        <span className={styles.heading_2}>
          <strong>Account Information</strong>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can: Log in to your
          account settings and update your user account.
        </span>
      </div>
      <div className={styles.body_text}>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            <u>Cookies and similar technologies:</u>
          </strong>{' '}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. You
          may also{' '}
          <span className={styles.body_text}>
            <a
              href="http://www.aboutads.info/choices/"
              target="_blank"
              rel="noopener noreferrer"
              data-custom-class="link"
            >
              opt out of interest-based advertising by advertisers
            </a>
          </span>{' '}
          on our Services.{' '}
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          If you have questions or comments about your privacy rights, you may
          email us at privacy@supergood.uno.
        </span>
      </div>
      <div id="DNT" className={styles.heading_1}>
        <strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
      </div>{' '}
      <div>
        <span className={styles.body_text}>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </span>
      </div>
      <div id="caresidents" className={styles.heading_1}>
        <strong>
          12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </strong>
      </div>{' '}
      <div>
        <span className={styles.body_text}>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </span>
      </div>
      <div>
        <span className={styles.heading_2}>
          <strong>CCPA Privacy Notice</strong>
        </span>
      </div>
      <div>
        <div>
          <span className={styles.body_text}>
            <span className={styles.body_text}>
              The California Code of Regulations defines a "resident" as:
            </span>
          </span>
        </div>
      </div>
      <div>
        <span className={styles.body_text}>
          (1) every individual who is in the State of California for other than
          a temporary or transitory purpose and
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          (2) every individual who is domiciled in the State of California who
          is outside the State of California for a temporary or transitory
          purpose
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          All other individuals are defined as "non-residents."
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>
            What categories of personal information do we collect?
          </strong>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </span>
      </div>
      <table>
        <tbody>
          <tr>
            <td>
              <span className={styles.body_text}>
                <strong>Category</strong>
              </span>
            </td>
            <td>
              <span className={styles.body_text}>
                <strong>Examples</strong>
              </span>
            </td>
            <td>
              <span className={styles.body_text}>
                <strong>Collected</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>A. Identifiers</span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  B. Personal information categories listed in the California
                  Customer Records statute
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  C. Protected classification characteristics under California
                  or federal law
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Gender and date of birth
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  D. Commercial information
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Transaction information, purchase history, financial details,
                  and payment information
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  E. Biometric information
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Fingerprints and voiceprints
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  F. Internet or other similar network activity
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>G. Geolocation data</span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>Device location</span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  I. Professional or employment-related information
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  J. Education Information
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Student records and directory information
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <span className={styles.body_text}>
                  K. Inferences drawn from other personal information
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className={styles.body_text}>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual&rsquo;s preferences and
                  characteristics
                </span>
              </div>
            </td>
            <td>
              <div>NO</div>
            </td>
          </tr>
          <tr>
            <td>
              <span className={styles.body_text}>
                L. Sensitive Personal Information
              </span>
            </td>
            <td></td>
            <td data-custom-class="body_text">
              <br />
              NO
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <span className={styles.body_text}>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </span>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            Receiving help through our customer support channels;
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Participation in customer surveys or contests; and
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </span>
        </li>
      </ul>
      <span className={styles.body_text}>
        <strong>How do we use and share your personal information?</strong>
      </span>
      <div className={styles.body_text}>
        More information about our data collection and sharing practices can be
        found in this privacy notice. You may contact us by email at
        ccpa@supergood.uno, or by referring to the contact details at the bottom
        of this document. If you are using an authorized agent to exercise your
        right to opt out we may deny a request if the authorized agent does not
        submit proof that they have been validly authorized to act on your
        behalf.
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>Will your information be shared with anyone else?</strong>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf, following the same strict privacy
          protection obligations mandated by the CCPA.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <strong>Your rights with respect to your personal data</strong>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <u>Right to request deletion of the data &mdash; Request to delete</u>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <u>Right to be informed &mdash; Request to know</u>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          Depending on the circumstances, you have a right to know:
        </span>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            whether we collect and use your personal information;
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            the categories of personal information that we collect;
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            the purposes for which the collected personal information is used;
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            whether we sell or share personal information to third parties;
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            the categories of personal information that we sold, shared, or
            disclosed for a business purpose;
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            the categories of third parties to whom the personal information was
            sold, shared, or disclosed for a business purpose;
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            the business or commercial purpose for collecting, selling, or
            sharing personal information; and
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            the specific pieces of personal information we collected about
            you.In accordance with applicable law, we are not obligated to
            provide or delete consumer information that is de-identified in
            response to a consumer request or to re-identify individual data to
            verify a consumer request.
          </span>
        </li>
      </ul>
      <div>
        <span className={styles.body_text}>
          <u>
            Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s
            Privacy Rights
          </u>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We will not discriminate against you if you exercise your privacy
          rights.
        </span>
      </div>
      <div>
        <u>
          <span className={styles.body_text}>
            Right to Limit Use and Disclosure of Sensitive Personal Information
          </span>
        </u>
      </div>
      <div>
        <span className={styles.body_text}>
          We do not process consumer's sensitive personal information.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <u>Verification process</u>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g., phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          <u>Other privacy rights</u>
        </span>
      </div>
      <ul>
        <li>
          <span className={styles.body_text}>
            You may object to the processing of your personal information.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            You may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            information.
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            agent to make a request under the CCPA on your behalf. We may deny a
            request from an authorized agent that does not submit proof that
            they have been validly authorized to act on your behalf in
            accordance with the CCPA.
          </span>
        </li>

        <li>
          <span className={styles.body_text}>
            You may request to opt out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the date of the request
            submission.
            <br />
            To exercise these rights, you can contact us by email at
            ccpa@supergood.uno, or by referring to the contact details at the
            bottom of this document. If you have a complaint about how we handle
            your data, we would like to hear from you.
          </span>
        </li>
      </ul>
      <div>
        <strong>
          <div id="virgina" className={styles.heading_1}>
            13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </div>
        </strong>
      </div>
      <div>
        <em>
          <strong>
            <span className={styles.body_text}>In Short:</span>
          </strong>
          <span className={styles.body_text}>
            &nbsp;Yes, if you are a resident of Virginia, you may be granted
            specific rights regarding access to and use of your personal
            information.
          </span>
        </em>
      </div>
      <div>
        <strong>
          <span className={styles.heading_2}>Virginia CDPA Privacy Notice</span>
        </strong>
      </div>
      <div>
        <span className={styles.body_text}>
          Under the Virginia Consumer Data Protection Act (CDPA):
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          "Consumer" means a natural person who is a resident of the
          Commonwealth acting only in an individual or household context. It
          does not include a natural person acting in a commercial or employment
          context.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          "Personal data" means any information that is linked or reasonably
          linkable to an identified or identifiable natural person. "Personal
          data" does not include de-identified data or publicly available
          information.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          "Sale of personal data" means the exchange of personal data for
          monetary consideration.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          If this definition "consumer" applies to you, we must adhere to
          certain rights and obligations regarding your personal data.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          The information we collect, use, and disclose about you will vary
          depending on how you interact with Supergood, LLC and our Services. To
          find out more, please visit the following links:
        </span>
      </div>
      <ul>
        <li>
          <HashLink to="#infocollect">Personal data we collect</HashLink>
        </li>
        <li>
          <HashLink to="#infouse">How we use your personal data</HashLink>
        </li>
        <li>
          <HashLink to="#whoshare">
            When and with whom we share your personal data
          </HashLink>
        </li>
      </ul>
      <span className={styles.body_text}>
        <u>Your rights with respect to your personal data</u>
      </span>
      <ul>
        <li>
          <span className={styles.body_text}>
            Right to be informed whether or not we are processing your personal
            data
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Right to access your personal data
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Right to correct inaccuracies in your personal data
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Right to request deletion of your personal data
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Right to obtain a copy of the personal data you previously shared
            with us
          </span>
        </li>
        <li>
          <span className={styles.body_text}>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
          </span>
        </li>
      </ul>
      <div className={styles.body_text}>
        Supergood, LLC has not sold any personal data to third parties for
        business or commercial purposes. Supergood, LLC will not sell personal
        data in the future belonging to website visitors, users, and other
        consumers.
      </div>
      <div>
        <u>
          <span className={styles.body_text}>
            Exercise your rights provided under the Virginia CDPA
          </span>
        </u>
      </div>
      <div>
        <span className={styles.body_text}>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          You may contact us by email at , or by referring to the contact
          details at the bottom of this document.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          If you are using an authorized to act on your behalf.
        </span>
      </div>
      <div>
        <u>
          <span className={styles.body_text}>Verification process</span>
        </u>
      </div>
      <div>
        <span className={styles.body_text}>
          We may request that you provide additional information reasonably
          necessary to verify you and your consumer's request. If you submit the
          request through an authorized agent, we may need to collect additional
          information to verify your identity before processing your request.
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          Upon receiving your request, we will respond without undue delay, but
          in all cases, within forty-five (45) days of receipt. The response
          period may be extended once by forty-five (45) additional days when
          reasonably necessary. We will inform you of any such extension within
          the initial 45-day response period, together with the reason for the
          extension.
        </span>
      </div>
      <div>
        <u>
          <span className={styles.body_text}>Right to appeal</span>
        </u>
      </div>
      <div>
        <span className={styles.body_text}>
          If we decline to take action regarding your request, we will inform
          you of our decision and reasoning behind it.&nbsp;
        </span>
        <span className={styles.body_text}>
          If you wish to appeal our decision, please email us at
          privacy@supergood.uno. Within sixty (60) days of receipt of an appeal,
          we will inform you in writing of any action taken or not taken in
          response to the appeal, including a written explanation of the reasons
          for the decisions. If your appeal if denied, you may contact the
          Attorney General to submit a complaint.
        </span>
      </div>
      <div>
        <strong>
          <div id="policyupdates" className={styles.heading_1}>
            14. DO WE MAKE UPDATES TO THIS NOTICE?
          </div>
        </strong>
      </div>
      <div>
        <span className={styles.body_text}>
          <em>
            <strong>In Short:&nbsp;</strong>
            Yes, we will update this notice as necessary to stay compliant with
            relevant laws.
          </em>
        </span>
      </div>
      <div>
        <span className={styles.body_text}>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </span>
      </div>
      <div>
        <strong>
          <div id="contact" className={styles.heading_1}>
            15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </div>
        </strong>
      </div>
      <div>
        <span className={styles.body_text}>
          If you have questions or comments about this notice, you may email us
          at or contact us by post at: privacy@supergood.uno
        </span>
      </div>
      <div>
        <strong>
          <div id="request" className={styles.heading_1}>
            16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </div>
        </strong>
      </div>
      <div>
        <span className={styles.body_text}>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. . To request to review, update,
          or delete your personal information, please email us at
          privacy@supergood.uno.
        </span>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
