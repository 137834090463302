// src/components/Settings.js

import React, { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import fonts from '../../../components/Fonts';
import FontSelector from '../../../components/FontSelector';
import SettingsContext from '../../../contexts/settings-context';
import withView from '../../../components/withView';
const Settings = ({ onClose }) => {
  //log view open
  const {
    settings: { clocker: settings },
    updateSetting,
  } = useContext(SettingsContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'is12Hour') {
      updateSetting('clocker', { [name]: value === 'true' ? true : false });
      return;
    }
    updateSetting('clocker', { [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    updateSetting('clocker', { [name]: checked ? true : false });
  };

  const commonTimeZones = [
    'Pacific/Honolulu',
    'America/Anchorage',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Argentina/Buenos_Aires',
    'America/Sao_Paulo',
    'Europe/London',
    'Europe/Paris',
    'Africa/Cairo',
    'Africa/Johannesburg',
    'Europe/Moscow',
    'Asia/Dubai',
    'Asia/Kolkata',
    'Asia/Shanghai',
    'Asia/Tokyo',
    'Australia/Sydney',
    'Pacific/Auckland',
  ];

  const fontSizes = [
    '60',
    '50',
    '40',
    '35',
    '30',
    '25',
    '20',
    '15',
    '10',
    '5',
    '1',
  ];

  return (
    <Form>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={4}>
          Background Color:
        </Form.Label>
        <Col sm={8}>
          <input
            type="color"
            name="backgroundColor"
            value={settings.backgroundColor}
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={4}>
          Font:
        </Form.Label>
        <Col sm={8}>
          <FontSelector
            name="font"
            fonts={fonts}
            value={settings.font}
            onChangeHandler={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={4}>
          Font Color:
        </Form.Label>
        <Col sm={8}>
          <input
            type="color"
            name="fontColor"
            value={settings.fontColor}
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={4}>
          Font Size:
        </Form.Label>
        <Col sm={8}>
          <select
            name="fontSize"
            value={settings.fontSize}
            onChange={handleChange}
          >
            {fontSizes.map((size) => (
              <option key={size} value={size}>
                {size}%
              </option>
            ))}
          </select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={4}>
          24 Hour Time:
        </Form.Label>
        <Col sm={8}>
          <select
            name="is12Hour"
            value={settings.is12Hour}
            onChange={handleChange}
          >
            <option value="true">Off</option>
            <option value="false">On</option>
          </select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={4}>
          Time Zone:
        </Form.Label>
        <Col sm={8}>
          <select
            name="timezone"
            value={settings.timezone}
            onChange={handleChange}
          >
            {commonTimeZones.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={4}>
          Display Seconds:
        </Form.Label>
        <Col sm={8}>
          <input
            type="checkbox"
            name="displaySeconds"
            checked={settings.displaySeconds === true}
            onChange={handleCheckboxChange}
          />
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withView(Settings, {
  type: 'modal',
  context: 'Settings',
  contextData: {},
});
