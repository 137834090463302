import Select from 'react-select';
const FontSelector = ({ fonts, onChangeHandler, name, value }) => {
  const sortedFonts = [...fonts].sort();

  const options = sortedFonts.map((font) => ({
    value: font,
    label: font,
    style: { fontFamily: 'Nabla' },
  }));

  const customStyles = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      display: 'inline-block',
      width: '200px',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: '20px',
      padding: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
      fontSize: '14px',
      padding: '0px',
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: '99999',
    }),
  };

  const handleChange = (selectedOption) => {
    const event = {
      target: {
        name: name,
        value: selectedOption.value,
      },
    };
    onChangeHandler(event);
  };

  return (
    <Select
      name={name}
      options={options}
      styles={customStyles}
      value={options.find((option) => option.value === value)} // Set the selected value
      onChange={handleChange}
      menuPortalTarget={document.body}
    />
  );
};

export default FontSelector;
