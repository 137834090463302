import React, { useContext, useEffect, useCallback } from 'react';
import SettingsContext from '../../../contexts/settings-context';
import { DateTime } from 'luxon';

const Clock = () => {
  const {
    settings: { clocker: settings },
  } = useContext(SettingsContext);
  const [date, setDate] = React.useState(
    DateTime.local().setZone(settings.timezone)
  );

  const determineFormat = useCallback((settings) => {
    let format;
    if (settings.is12Hour === true) {
      if (settings.displaySeconds === true) {
        format = 'h:mm:ss a';
      } else {
        format = 'h:mm a';
      }
    } else {
      if (settings.displaySeconds === true) {
        format = 'HH:mm:ss';
      } else {
        format = 'HH:mm';
      }
    }
    return format;
  }, []);

  const [format, setFormat] = React.useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(DateTime.local().setZone(settings.timezone));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [settings.timezone]);

  useEffect(() => {
    // console.log('settings changed', settings);

    setFormat(determineFormat(settings));
  }, [settings, determineFormat]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: settings.backgroundColor,
        fontFamily: settings.font,
        color: settings.fontColor,
        fontSize: `${settings.fontSize}vw`,
        transition: 'all 0.5s ease',
      }}
    >
      {date.toFormat(format)}
    </div>
  );
};

export default Clock;
