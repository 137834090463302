// src/Clocker.js

import React, { useEffect, useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Clock from './components/Clock';
import FullScreenButton from './components/FullScreenButton';
import SettingsButton from './components/SettingsButton';
import Settings from './components/Settings';
import SettingsContext from '../../contexts/settings-context';
import styles from './css/Clocker.module.css';
import withView from '../../components/withView';
const Clocker = ({ trackEvent }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const {
    settings: { clocker: settings },
  } = useContext(SettingsContext);

  useEffect(() => {
    document.body.style.overflow = isFullScreen ? 'hidden' : 'auto';
    document.body.style.margin = 0;
    document.body.style.padding = 0;
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () =>
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
  }, [isFullScreen]);

  const handleSettingsOpen = () => {
    trackEvent({ action: 'click', element: 'settings' });
    setIsSettingsOpen(true);
  };
  const handleSettingsClose = () => setIsSettingsOpen(false);

  const handleSettingsOpened = () => {};

  const handleFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      /* Firefox */
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE/Edge */
      document.documentElement.msRequestFullscreen();
    }
    trackEvent({ action: 'click', element: 'fullscreen' });
    console.log('handleFullScreen');
    setIsFullScreen(true);
  };

  return (
    <div
      className={styles.clockerContainer}
      style={{
        backgroundColor: settings.backgroundColor,
      }}
    >
      <Clock />
      {!isFullScreen && (
        <div className={styles.buttonBar}>
          <SettingsButton onClick={handleSettingsOpen} />
          <FullScreenButton onClick={handleFullScreen} />
        </div>
      )}

      <Modal
        show={isSettingsOpen}
        onHide={handleSettingsClose}
        onShow={handleSettingsOpened}
        dialogClassName={styles.settingsModal}
        backdrop={false}
        style={{ zIndex: 20 }}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Settings onClose={handleSettingsClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default withView(Clocker, {
  type: 'page',
  context: 'Clocker',
  contextData: {},
});
