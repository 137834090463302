import React, { useEffect, useContext } from 'react';
import { analytics } from './firebase';
import { setConsent, logEvent } from 'firebase/analytics';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SettingsContext from './contexts/settings-context';
import AuthRootLayout from './layouts/AuthRoot';
import Clocker from './apps/clocker/Clocker';
import Home from './Home';
import ErrorPage from './pages/Error';
import PrivacyPolicy from './PrivacyPolicy';
import Consenter from './components/Consenter';
import Meter from './apps/meter/Meter';
import '@fontsource/inter';

const router = createBrowserRouter([
  { path: '/clocker', element: <Clocker /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/', element: <Home /> },
  {
    path: '/apps',
    element: <AuthRootLayout />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => 'Home',
    },
    children: [
      {
        path: 'meter',
        element: <Meter />,
        handle: {
          crumb: () => 'Meter',
        },
      },
    ],
  },
]);

function App() {
  const {
    settings: { consent },
    updateSetting,
  } = useContext(SettingsContext);

  const onAcceptHandler = () => {
    console.log('onAcceptHandler');
    setConsent({
      analytics_storage: 'granted',
      ad_storage: 'granted',
    });
    updateSetting('consent', { analytics: true });
  };

  const onDeclineHandler = () => {
    setConsent({
      analytics_storage: 'denied',
      ad_storage: 'denied',
    });
    updateSetting('consent', { analytics: false });
  };

  useEffect(() => {
    if (consent.analytics) {
      console.log('consent is active');
      setConsent({
        analytics_storage: 'granted',
        ad_storage: 'granted',
      });
    } else {
      setConsent({
        analytics_storage: 'denied',
        ad_storage: 'denied',
      });
    }
    logEvent(analytics, 'consent_changed');
  }, [consent]);

  useEffect(() => {
    console.log('App Starting');
  }, []);

  return (
    <>
      <RouterProvider router={router} />
      <Consenter onAccept={onAcceptHandler} onDecline={onDeclineHandler} />
    </>
  );
}

export default App;
