// src/components/SettingsButton.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const SettingsButton = ({ onClick }) => {
  return (
    <button onClick={onClick} style={{ margin: '0 5px' }}>
      <FontAwesomeIcon icon={faCog} />
    </button>
  );
};

export default SettingsButton;
