import { Outlet, Navigate, useLocation, useMatches } from 'react-router-dom';
// import { useContext } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
// import UserContext from '../contexts/user-context';
import withBody from './withBody';

function AuthRootLayout({ children }) {
  const location = useLocation();
  let matches = useMatches();
  //   const { user, logOut } = useContext(UserContext);
  const user = true;
  console.log('navigation: ', location);
  console.log('matches: ', matches);

  //   const handleLogOut = async () => {
  //     try {
  //       await logOut();
  //     } catch (err) {
  //       console.log('Could not log out properly', err);
  //     }
  //   };

  if (!user) {
    return <Navigate to="/login" replace />;
  } else {
    console.log('Root layout mumbo');
    const WrappedOutlet = withBody(Outlet);
    return (
      <CssVarsProvider disableTransitionOnChange defaultMode="light">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            minHeight: '100dvh',
          }}
        >
          {/* <Header /> */}
          <Box
            component="main"
            className="MainContent"
            sx={{
              //   pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
              pb: { xs: 2, sm: 2, md: 3 },
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              height: '100dvh',
              gap: 1,
              overflow: 'auto',
            }}
          >
            <WrappedOutlet />
          </Box>
        </Box>
      </CssVarsProvider>
    );
  }
}
export default AuthRootLayout;
