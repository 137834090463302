import React, { useContext, useEffect } from 'react';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import Sheet from '@mui/joy/Sheet';
import Slider from '@mui/joy/Slider';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import Button from '@mui/joy/Button';
import SettingsContext from '../../../contexts/settings-context';

function MeterSettings({ open, onCloseHandler }) {
  const maxVolume = 120;
  const {
    settings: { meter: settings },
    updateSetting,
  } = useContext(SettingsContext);

  const handleChange = ({ name, value }) => {
    console.log('handleChange', name, value);
    updateSetting('meter', { [name]: value });
  };

  useEffect(() => {
    console.log('settings', settings);
  }, [settings]);

  return (
    <Drawer
      anchor="top"
      size="sm"
      variant="plain"
      open={open}
      onClose={onCloseHandler}
      slotProps={{
        content: {
          sx: {
            bgcolor: 'light.background',
            p: { sm: 2 },
            boxShadow: 'none',
          },
        },
      }}
    >
      <Sheet
        sx={{
          borderRadius: 'md',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '100%',
        }}
      >
        {/* Drawer content */}
        <Typography>Settings</Typography>
        <ModalClose />

        <Divider sx={{ mt: 'auto' }} />
        <Box sx={{ gap: 2 }}>
          <Grid
            container
            display="flex"
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="row"
            sx={{ marginTop: '10px' }}
          >
            <Grid display="flex" xs={2}>
              <Typography>Sensitivity: {settings.triggerVolume}</Typography>
            </Grid>
            <Grid
              display="flex"
              xs={10}
              justifyContent="flex-start"
              alignItems="flex-start"
              justifyItems="flex-start"
              alignContent="flex-start"
            >
              <Slider
                sx={{
                  width: '60%',
                  marginLeft: '0',
                }}
                color="primary"
                size="md"
                valueLabelDisplay="on"
                aria-label="Adjust Trigger Volume"
                min={0}
                max={maxVolume}
                value={settings.triggerVolume}
                onChange={(event, value) =>
                  handleChange({ name: 'triggerVolume', value })
                }
              />
            </Grid>
            <Grid display="flex" xs={2}>
              <Typography>Video URL</Typography>
            </Grid>
            <Grid xs={10}>
              <Input
                placeholder="Video URL"
                size="md"
                variant="outlined"
                sx={{ maxWidth: '450px' }}
                value={settings.videoURL}
                onChange={(event) =>
                  handleChange({ name: 'videoURL', value: event.target.value })
                }
              />
            </Grid>
            <Grid display="flex" xs={2}>
              <Typography>Playback Seconds</Typography>
            </Grid>
            <Grid xs={4}>
              <Input
                size="md"
                variant="outlined"
                sx={{ width: '50px' }}
                value={settings.playbackSeconds}
                onChange={(event) =>
                  handleChange({
                    name: 'playbackSeconds',
                    value: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid display="flex" xs={3}>
              <Typography>Sustained Trigger Volume</Typography>
            </Grid>
            <Grid xs={3}>
              <Input
                size="md"
                variant="outlined"
                sx={{ width: '50px' }}
                value={settings.triggerVolumeSeconds}
                onChange={(event) =>
                  handleChange({
                    name: 'triggerVolumeSeconds',
                    value: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid display="flex" xs={2}>
              &nbsp;
            </Grid>
            <Grid xs={10}>
              <Button onClick={onCloseHandler}>Save</Button>
            </Grid>
          </Grid>
        </Box>
      </Sheet>
    </Drawer>
  );
}

export default MeterSettings;
