import { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import AspectRatio from '@mui/joy/AspectRatio';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import FullScreenIcon from '@mui/icons-material/Fullscreen';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExit';
import Typography from '@mui/joy/Typography';
import ReactPlayer from 'react-player/lazy';
import AudioLevelMeter from './components/AudioLevelMeter';
import useAudioMonitor from './useAudioMonitor';
import MeterSettings from './components/MeterSettings';
import SettingsContext from '../../contexts/settings-context';
import Header from '../../layouts/Header';
import IconButton from '@mui/joy/IconButton';
const maxVolume = 120;

function Meter() {
  const fullScreenHandle = useFullScreenHandle();
  const videoContainerRef = useRef(null);
  const {
    settings: { meter: settings },
  } = useContext(SettingsContext);

  const fullScreenActive = fullScreenHandle.active;

  const playerRef = useRef(null);
  const meterContainerRef = useRef(null);
  const { volume, setIsMonitoring } = useAudioMonitor({
    triggerVolumeSeconds: settings.triggerVolumeSeconds,
    triggerVolume: settings.triggerVolume,
  });
  const [armed, setArmed] = useState(false);
  const [activeTrigger, setActiveTrigger] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [startTime, setStartTime] = useState(0);

  const toggleMonitoringState = () => {
    if (armed) {
      setArmed(false);
    } else {
      setArmed(true);
    }
  };

  useEffect(() => {
    setIsMonitoring(true);
  }, [setIsMonitoring]);

  const resetTrigger = useCallback(() => {
    setActiveTrigger(false);
    // Clear the timer
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }
  }, [timerId]);

  useEffect(() => {
    if (
      volume.trailing != null &&
      volume.trailing >= settings.triggerVolume &&
      armed &&
      !activeTrigger
    ) {
      playerRef.current.seekTo(startTime, 'seconds');
      // findDOMNode(playerRef.current).webkitRequestFullscreen();
      if (fullScreenActive) {
      }
      setActiveTrigger(true);
      console.log('triggered');
      console.log('settings.playbackSeconds', settings.playbackSeconds);

      const timer = setTimeout(() => {
        console.log('timer expired');
        resetTrigger();
      }, settings.playbackSeconds * 1000);

      setTimerId(timer);
    }
  }, [
    volume,
    settings.triggerVolume,
    settings.playbackSeconds,
    armed,
    resetTrigger,
    activeTrigger,
    startTime,
    fullScreenActive,
  ]);

  const recordStartTime = () => {
    console.log(`current time ${playerRef.current.getCurrentTime()}`);
    setStartTime(playerRef.current.getCurrentTime());
  };

  return (
    <>
      <Header title="Meter" />
      <Box
        id="meter-root"
        sx={{
          alignItems: 'center',
          // height: '100vh',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <MeterSettings
          open={settingsOpen}
          onCloseHandler={() => setSettingsOpen(false)}
          maxVolume={maxVolume}
        />
        <FullScreen handle={fullScreenHandle}>
          <Box
            id="outerbox"
            sx={{
              backgroundColor: 'white',
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              id="meter-container"
              ref={meterContainerRef}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '80vw',
                width: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
                height: '100%',
                backgroundColor: 'white',
                overflow: 'hidden',
              }}
            >
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  maxHeight: 'calc(100vh - 200px)',
                  // height: '76vh',
                  marginBottom: '10px',
                  overflow: 'hidden',
                }}
              >
                <Box>
                  <Typography level="title-lg">
                    {activeTrigger ? 'TOO LOUD' : 'All Quiet'}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      if (fullScreenHandle.active) {
                        fullScreenHandle.exit();
                      } else {
                        fullScreenHandle.enter();
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: '0.25rem',
                      right: '0.25rem',
                    }}
                    size="lg"
                  >
                    {fullScreenHandle.active ? (
                      <FullScreenExitIcon />
                    ) : (
                      <FullScreenIcon />
                    )}
                  </IconButton>
                </Box>
                <Box
                  id="meter-video-container"
                  sx={{ width: '100%', height: '100%' }}
                  ref={videoContainerRef}
                >
                  <AspectRatio
                    id="meter-video"
                    variant="outlined"
                    objectFit="contain"
                    ratio="16/9"
                    sx={(theme) => ({
                      [theme.breakpoints.up('xs')]: {
                        // width: '200px',
                        width: '100%',

                        // backgroundColor: 'red',
                        // flexDirection: 'column',
                        // alignContent: 'center',
                      },
                      [theme.breakpoints.up('sm')]: {
                        // backgroundColor: 'blue',
                      },
                      // backgroundColor: 'yellow',
                      maxWidth: '1280px',
                      margin: 'auto',
                    })}
                  >
                    <ReactPlayer
                      url={settings.videoURL}
                      playing={activeTrigger}
                      onPlay={() => {
                        console.log('playing');
                      }}
                      onReady={() => {
                        recordStartTime();
                      }}
                      ref={playerRef}
                      width="100%"
                      height="100%"
                      style={{
                        // hide if active trigger is false
                        display: activeTrigger ? 'block' : 'none',
                      }}
                    />
                  </AspectRatio>
                </Box>
              </Card>
              <AudioLevelMeter
                volume={volume}
                triggerVolume={settings.triggerVolume}
                maxVolume={maxVolume}
                sx={{
                  width: '100%',
                }}
              />
              <Box
                sx={{
                  backgroundColor: '#FBFCFE',
                  border: '0px solid black',
                  padding: '10px',
                  borderTop: 'none',
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Button
                  color="neutral"
                  variant="outlined"
                  onClick={() => {
                    toggleMonitoringState();
                  }}
                  title="Start/Stop"
                  startDecorator={
                    armed ? (
                      <StopCircleOutlinedIcon />
                    ) : (
                      <PlayCircleOutlineOutlinedIcon />
                    )
                  }
                >
                  {armed ? 'Disable Monitor' : 'Enable Montior'}
                </Button>
                <Button
                  color="neutral"
                  variant="outlined"
                  startDecorator={<RestartAltIcon />}
                  onClick={() => {
                    resetTrigger();
                  }}
                  title="Reset"
                >
                  Restart
                </Button>
                <Button
                  color="neutral"
                  variant="outlined"
                  startDecorator={<SettingsOutlinedIcon />}
                  onClick={() => {
                    setSettingsOpen(!settingsOpen);
                  }}
                  title="Settings"
                >
                  Settings
                </Button>
              </Box>
              <Box
                id="meter-text"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                  marginBottom: '100px',
                }}
              >
                <Box sx={{ width: '200px', alignSelf: 'flex-start' }}>
                  Current: {volume.instant} db
                </Box>
                <Box sx={{ width: '200px', alignSelf: 'flex-end' }}>
                  Trailing: {volume.trailing} dB
                </Box>
                <Box sx={{ width: '200px', alignSelf: 'flex-end' }}>
                  Trigger Time:{' '}
                  {volume.triggerTimePercent != null
                    ? volume.triggerTimePercent.toFixed(0)
                    : ''}
                  %
                </Box>
              </Box>
            </Box>
          </Box>
        </FullScreen>
      </Box>
    </>
  );
}

export default Meter;
