import React from 'react';
import CookieConsent from 'react-cookie-consent';

function Consenter({ onAccept, onDecline }) {
  return (
    <CookieConsent
      onAccept={onAccept}
      onDecline={onDecline}
      enableDeclineButton
      declineButtonText="Decline"
      location="bottom"
      buttonText="Allow"
      disableStyles={true}
      style={{
        background: '#D4E4F7',
        position: 'fixed',
        bottom: '0',
        left: '50%',
        transform: 'translate(-50%)',
        maxWidth: '1000px',
        width: '80%',
        marginBottom: '20px',
        boxShadow: '0px 0px 24px -5px grey',
        padding: '8px',
        display: 'flex',
        // flexWrap: 'wrap',
        justifyContent: 'space-between',
        // position: 'fixed',
        zIndex: '999',
      }}
      buttonStyle={{
        backgroundColor: '#14B884',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '13px',
        marginLeft: '12px',
        marginRight: '8px',
      }}
      declineButtonStyle={{
        color: 'white',
        fontSize: '13px',
        backgroundColor: '#FD4D0C',
      }}
      contentStyle={{ maxWidth: '80%', fontSize: '13px' }}
    >
      This website uses cookies to ensure you get the best experience on our
      website. &nbsp;
      <a href="/privacy-policy" target="new">
        Learn More
      </a>
    </CookieConsent>
  );
}

export default Consenter;
