// src/components/FullScreenButton.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

const FullScreenButton = ({ onClick }) => {
  return (
    <button onClick={onClick} style={{ margin: '0 5px' }}>
      <FontAwesomeIcon icon={faExpand} />
    </button>
  );
};

export default FullScreenButton;
