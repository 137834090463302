import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
function Home() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>App Homepage</h1>
          <Link to="/Clocker">Clock</Link>
          <br></br>
          <Link to="/apps/Meter">Meter</Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
