const fonts = [
  'Bebas Neue',
  'Courier Prime',
  'Comic Sans MS',
  'Noto Sans',
  'Open Sans',
  'Roboto',
  'Newsreader',
  'Montserrat',
  'Dosis',
  'Anton',
  'Orbitron',
  'Source Code Pro',
  'Bungee Spice',
  'Nabla',
  'Press Start 2P',
  'Luckiest Guy',
  'Silkscreen',
  'Foldit',
];

export default fonts;
