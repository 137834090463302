import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

// SGTracker.js
const dispatch = (eventData) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('dispatchData', eventData);
  }
  if (eventData.action === 'pageView') {
    logEvent(analytics, 'page_view', { page_title: eventData.page });
  } else if (eventData.action === 'modalView') {
    logEvent(analytics, 'page_view', {
      page_title: eventData.page + ':' + eventData.modal,
    });
  }
};

const defaults = { version: '1.0.0' };

const SGTracker = {
  dispatch,
  defaults,
};

export default SGTracker;
