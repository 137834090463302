import Box from '@mui/joy/Box';

const height = '30px';

function AudioLevelMeter({ volume, triggerVolume, maxVolume }) {
  return (
    <Box
      sx={{
        border: '#000 2px solid',
        width: '100%',
        padding: '0px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '4px',
          backgroundColor: 'lightcoral',
          zIndex: 200,
          left: `${(triggerVolume / maxVolume) * 100}%`,
          position: 'absolute',
        }}
      ></Box>
      <Box
        sx={{
          height: height,
          position: 'absolute',
          zIndex: 100,
          width: `${(volume.trailing / maxVolume) * 100}%`,
          backgroundColor:
            volume.trailing >= triggerVolume
              ? 'red'
              : volume.trailing >= triggerVolume * 0.5
              ? 'yellow'
              : 'green',
          filter: 'brightness(50%)',
        }}
      ></Box>
      <Box
        sx={{
          height: height,
          width: `${(volume.instant / maxVolume) * 100}%`,
          backgroundColor:
            volume.instant >= triggerVolume
              ? 'red'
              : volume.instant >= triggerVolume * 0.5
              ? 'yellow'
              : 'green',
        }}
      ></Box>
    </Box>
  );
}

export default AudioLevelMeter;
