import React, { useReducer, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Cookies from 'js-cookie';

import SettingsContext from './settings-context';

const defaultSettings = {
  clocker: {
    backgroundColor: '#627514',
    font: 'Nabla',
    fontColor: '#ffffff',
    fontSize: '20',
    is12Hour: true,
    timezone: DateTime.local().zoneName,
    displaySeconds: true,
  },
  meter: {
    triggerVolume: 80,
    videoURL: 'https://www.youtube.com/watch?v=Dkk9gvTmCXY&t=49',
    playbackSeconds: 10,
    triggerVolumeSeconds: 5,
  },
  consent: { analytics: false },
};

const mergeWithDefaults = (cookieSettings) => {
  return Object.keys(defaultSettings).reduce((mergedSettings, key) => {
    mergedSettings[key] = {
      ...defaultSettings[key],
      ...cookieSettings[key],
    };
    return mergedSettings;
  }, {});
};

const settingsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SETTINGS': {
      return {
        ...state,
        [action.appName]: {
          ...state[action.appName],
          ...action.updatedValues,
        },
      };
    }
    default:
      return state;
  }
};

const SettingsProvider = ({ children }) => {
  const initialCookieSettings = Cookies.get('settings')
    ? JSON.parse(Cookies.get('settings'))
    : {};

  const [cookieSettings, setCookieSettings] = useState(initialCookieSettings);
  // State to hold the changed settings
  const [settings, dispatch] = useReducer(
    settingsReducer,
    mergeWithDefaults(initialCookieSettings)
  );
  // Function to get a setting, falling back to the default if not found

  const updateSetting = (appName, updatedValues) => {
    console.log('updateSetting', appName, updatedValues);
    dispatch({
      type: 'UPDATE_SETTINGS',
      appName,
      updatedValues,
    });
    setCookieSettings((prevCookieSettings) => {
      return {
        ...prevCookieSettings,
        [appName]: {
          ...prevCookieSettings[appName],
          ...updatedValues,
        },
      };
    });
  };

  // Use the useEffect hook to observe changes in the settings state
  useEffect(() => {
    // Update the cookie with the changed settings
    Cookies.set('settings', JSON.stringify(cookieSettings), { expires: 365 });
  }, [cookieSettings]);

  const settingsContext = {
    settings,
    updateSetting,
  };

  return (
    <SettingsContext.Provider value={settingsContext}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
