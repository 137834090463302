// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBVSTU6pJn7lB255ZF7burDLSVhovnHL-M',
  authDomain: 'supergood-apps.firebaseapp.com',
  projectId: 'supergood-apps',
  storageBucket: 'supergood-apps.appspot.com',
  messagingSenderId: '757190965512',
  appId: '1:757190965512:web:c55d4cb5c41f82126094bd',
  measurementId: 'G-5BV4C5N23T',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const analytics = getAnalytics(firebaseApp);
console.log('firebase initialized');
