import { useState, useEffect, useRef } from 'react';

const useAudioMonitor = ({ triggerVolumeSeconds, triggerVolume }) => {
  const [volume, setVolume] = useState({
    instant: 0,
    trailing: 0,
    history: [],
    isMinimumTimePast: false,
  });
  const [isMonitoring, setIsMonitoring] = useState(false);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const lastUpdateRef = useRef(null);

  useEffect(() => {
    async function startMonitoring() {
      if (!isMonitoring) return;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        audioContextRef.current = new AudioContext();
        const audioSource =
          audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256;
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);

        audioSource.connect(analyserRef.current);
        updateVolume(analyserRef.current, dataArray);
      } catch (err) {
        console.error('Error accessing the microphone', err);
      }
    }

    function updateVolume(analyser, dataArray, timestamp) {
      if (!isMonitoring) return;

      if (lastUpdateRef.current && timestamp - lastUpdateRef.current < 100) {
        animationFrameIdRef.current = requestAnimationFrame((t) =>
          updateVolume(analyser, dataArray, t)
        );

        return;
      }
      lastUpdateRef.current = timestamp;
      const triggerVolumeMilliseconds = triggerVolumeSeconds * 1000;
      analyser.getByteFrequencyData(dataArray);

      let rms = 0;
      for (var i = 0; i < dataArray.length; i++) {
        if (dataArray[i] > 120) dataArray[i] = 120;
        rms += dataArray[i] * dataArray[i];
      }
      rms = Math.sqrt(rms / dataArray.length);
      // Add the current volume to the pastVolumes array

      setVolume((prevVolume) => {
        // Add the current volume to the pastVolumes array
        const newTimestamp = Date.now();
        let isMinimumTimePast = prevVolume.isMinimumTimePast;
        const newPastVolumes = [
          ...prevVolume.history,
          { volume: rms, timestamp: newTimestamp },
        ];

        // Remove volumes that are older than X seconds
        const updatedPastVolumes = newPastVolumes.filter(
          (volume) =>
            newTimestamp - volume.timestamp <= triggerVolumeMilliseconds
        );

        if (!isMinimumTimePast) {
          //compare the earliest timestamp to the latest timestamp
          if (
            updatedPastVolumes[updatedPastVolumes.length - 1].timestamp -
              updatedPastVolumes[0].timestamp >=
            triggerVolumeMilliseconds
          ) {
            isMinimumTimePast = true;
          }
        }

        let percentageAtOrAboveTrigger = 0;
        if (isMinimumTimePast) {
          // Calculate the percentage of past volumes that are at or above the triggerVolume
          const volumesAtOrAboveTrigger = updatedPastVolumes.filter(
            (volume) => volume.volume >= triggerVolume
          );
          percentageAtOrAboveTrigger =
            (volumesAtOrAboveTrigger.length / updatedPastVolumes.length) * 100;
        }

        // console.log('timePast', isMinimumTimePast);
        // Calculate the average volume
        const averageVolume =
          updatedPastVolumes.reduce((sum, volume) => {
            return sum + volume.volume;
          }, 0) / updatedPastVolumes.length;

        // Return the new state
        return {
          instant: rms.toFixed(0),
          trailing: isMinimumTimePast ? averageVolume.toFixed(0) : null,
          history: updatedPastVolumes,
          isMinimumTimePast,
          triggerTimePercent: percentageAtOrAboveTrigger,
        };
      });

      animationFrameIdRef.current = requestAnimationFrame((t) =>
        updateVolume(analyser, dataArray, t)
      );
    }

    startMonitoring();

    return () => {
      stopMonitoring();
    };
  }, [isMonitoring, triggerVolumeSeconds, triggerVolume]);

  const stopMonitoring = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
    }
  };

  return { volume, setIsMonitoring, isMonitoring };
};

export default useAudioMonitor;
