// import { useMatches } from 'react-router-dom';
import Box from '@mui/joy/Box';

const withBody = (WrappedComponent) => {
  // let matches = useMatches();
  // let crumbs = matches
  //   // first get rid of any matches that don't have handle and crumb
  //   .filter((match) => Boolean(match.handle?.crumb))
  //   // now map them into an array of elements, passing the loader
  //   // data to each one
  //   .map((match) => match.handle.crumb(match.data));

  // const pageTitle = crumbs[crumbs.length - 1];

  return (props) => (
    <Box id="with-body-box" sx={{ flex: 1, width: '100%' }}>
      {/* <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      > */}
      <Box id="wrapped-box" sx={{ px: 0 }}>
        {/* <Box id="wrapped-box" sx={{ px: { xs: 2, md: 6 } }}> */}
        <WrappedComponent {...props} />
      </Box>
      {/* </Box> */}
    </Box>
  );
};

export default withBody;
